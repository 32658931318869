@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Graphikreg";
  src: url("../public/fonts/Graphik-Regular.otf");
}

body {
  margin: 0;
  font-family: "Graphikreg", "Open Sans", i-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
  scrollbar-color: rgb(130 219 194) #ffffff;
  scrollbar-width: thin;
}

html {
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

input[type="checkbox"] {
  border: 1px solid rgb(130 219 194);
  border-radius: 2px;
}
input[type="checkbox"]:checked {
  background-color: rgb(130 219 194);
  border: 1px solid rgb(130 219 194);
  position: relative;
}
input[type="checkbox"]:checked:before {
  content: "\2713";
  font-size: 12px;
  font-weight: bold;
  color: white;
  position: absolute;
  left: 2px;
  top: -2px;
}

/* Scrollbar */

/* Hide scrollbar for Chrome, Safari and Opera */
.files-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.files-list {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(229 231 235);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(130 219 194);
}
/* Search input */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
